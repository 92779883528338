<template>
    <CardWithHelp>
        <template v-slot:header>
            <b-icon-geo-alt font-scale="1.2" />
            <div>Mes installations</div>
        </template>

        <template v-slot:help>
            <ul class="k-help-list">
                <li>Consulter la liste de mes installations</li>
                <li>Visualiser mes installations sur une carte</li>
            </ul>
        </template>

        <template v-slot:content>
            <div class="panel-content">
                <div class="k-card-1">
                    <h1 class="k-flex-row">
                        Général
                        <div class="k-flex-line" />
                    </h1>

                    <div class="k-flex-row">
                        <small>Nombre d'installations</small>
                        <div class="count">
                            <b>{{ installData.total }}</b>
                        </div>
                    </div>

                    <div>
                        <small>Type d'installations</small>
                    </div>
                    <div class="text-center">
                        <div class="k-flex-center k-gap-s">
                            <b class="count">{{ installData.table }}</b>
                            {{ "table" | plural(installData.table) }}
                        </div>
                        <div class="k-flex-center k-gap-s">
                            <b class="count">{{ installData.borne }}</b>
                            {{ "borne" | plural(installData.borne) }}
                        </div>
                    </div>
                </div>

                <div class="k-card-1">
                    <h1 class="k-flex-row">
                        Données application
                        <div class="k-flex-line" />
                    </h1>

                    <div class="k-flex-row">
                        <small>Mises à jour aujourd'hui</small>
                        <b
                            class="count"
                            :class="{
                                'text-secondary': installData.sitToday == 0,
                            }"
                        >
                            {{ installData.sitToday }}
                        </b>
                    </div>

                    <div class="k-flex-row">
                        <small>Mises à jour hier</small>
                        <b
                            class="count"
                            :class="{
                                'text-secondary': installData.sitYesterday == 0,
                                'text-warning': installData.sitYesterday > 0,
                            }"
                        >
                            {{ installData.sitYesterday }}
                        </b>
                    </div>

                    <div class="k-flex-row">
                        <small>Sans mise à jour récente</small>
                        <b
                            class="count"
                            :class="{
                                'text-danger': installData.sitLonger > 0,
                            }"
                        >
                            {{ installData.sitLonger }}
                        </b>
                    </div>
                </div>

                <div class="k-card-1">
                    <h1 class="k-flex-row">
                        Connexion
                        <div class="k-flex-line" />
                    </h1>

                    <div class="k-flex-row">
                        <small>Connectées aujourd'hui</small>
                        <b
                            class="count"
                            :class="{
                                'text-secondary':
                                    installData.teamviewerToday == 0,
                            }"
                        >
                            {{ installData.teamviewerToday }}
                        </b>
                    </div>

                    <div class="k-flex-row">
                        <small>Sans connexion récente</small>
                        <b
                            class="count"
                            :class="{
                                'text-warning':
                                    installData.teamviewerLonger > 0,
                            }"
                        >
                            {{ installData.teamviewerLonger }}
                        </b>
                    </div>

                    <div class="k-flex-row">
                        <small>Sans information</small>
                        <b
                            class="count"
                            :class="{
                                'text-warning':
                                    installData.teamviewerNoData > 0,
                            }"
                        >
                            {{ installData.teamviewerNoData }}
                        </b>
                    </div>
                </div>

                <div v-show="false">
                    <div
                        class="border"
                        v-for="install in installations"
                        :key="install.id"
                    >
                        <div>{{ install.type }}</div>
                        <div>{{ install.name }}</div>
                        <div>{{ install.place || "Lieu non définie" }}</div>
                        <div>{{ install.contactedAt || "Jamais" }}</div>
                    </div>
                </div>
            </div>

            <div class="k-flex-center">
                <b-button
                    size="sm"
                    variant="kalkin-2"
                    :to="{ name: 'ProjectInstallations' }"
                >
                    Plus de détails <b-icon-arrow-right-circle />
                </b-button>
            </div>
        </template>
    </CardWithHelp>
</template>

<script>
import CardWithHelp from "@/components/ui/item/CardWithHelp";
export default {
    components: {
        CardWithHelp,
    },

    props: {
        installations: { type: Array },
    },

    computed: {
        installData() {
            const today = new Date().toDateString();
            const yesterday = new Date(Date.now() - 864e5).toDateString();

            if (this.installations) {
                return this.installations.reduce(
                    (acc, cur) => {
                        acc.total++;
                        acc[cur.type.toLowerCase()]++;

                        const sitDate = new Date(
                            cur.contactedAt,
                        ).toDateString();
                        if (sitDate === today) {
                            acc.sitToday++;
                        } else if (sitDate === yesterday) {
                            acc.sitYesterday++;
                        } else {
                            acc.sitLonger++;
                        }
                        if (cur.teamviewer) {
                            if (cur.teamviewer.online) {
                                acc.teamviewerOnline++;
                                acc.teamviewerToday++;
                            } else if (cur.teamviewer.lastSeen) {
                                const tvDate = new Date(
                                    cur.teamviewer.lastSeen,
                                ).toDateString();
                                if (tvDate === today) {
                                    acc.teamviewerToday++;
                                } else {
                                    acc.teamviewerLonger++;
                                }
                            } else {
                                acc.teamviewerNoData++;
                            }
                        } else {
                            acc.teamviewerNoData++;
                        }
                        return acc;
                    },
                    {
                        table: 0,
                        borne: 0,
                        total: 0,
                        sitToday: 0,
                        sitYesterday: 0,
                        sitLonger: 0,
                        teamviewerOnline: 0,
                        teamviewerToday: 0,
                        teamviewerLonger: 0,
                        teamviewerNoData: 0,
                    },
                );
            } else {
                return {
                    table: "?",
                    borne: "?",
                    total: "?",
                    sitToday: "?",
                    sitYesterday: "?",
                    sitLonger: "?",
                    teamviewerOnline: "?",
                    teamviewerToday: "?",
                    teamviewerLonger: "?",
                    teamviewerNoData: "?",
                };
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.panel-content {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;

    height: 100%;

    & > * {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 5px;
        min-width: 220px;
        padding: 10px 5px;
        line-height: 1.15;
        & > * {
            padding: 0 10px;
        }
        & > :first-child {
            padding: 0 5px;
        }
    }

    & .count {
        color: $secondary-color;
        font-size: 1.5em;
    }
}
</style>
