<template>
    <CardWithHelp>
        <template v-slot:header>
            <b-icon-people font-scale="1.2" />
            <div>Utilisateurs</div>
        </template>

        <template v-slot:help>
            <ul class="k-help-list">
                <li>Voir les membres du projet</li>
                <li>Voir les contacts du projet</li>
            </ul>
        </template>

        <template v-slot:content>
            <div class="flex-grow-1" />
            <div class="text-center">
                <em>En cours de développement</em>
            </div>
            <div class="flex-grow-1" />
            <div class="k-flex-center">
                <b-button size="sm" variant="secondary" disabled>
                    Voir les utilisateurs <b-icon-arrow-right-circle />
                </b-button>
            </div>
        </template>
    </CardWithHelp>
</template>

<script>
import CardWithHelp from "@/components/ui/item/CardWithHelp";
export default {
    components: {
        CardWithHelp,
    },
};
</script>
