<template>
    <div class="k-card-4">
        <div class="header">
            <slot name="header" />
            <div style="flex:1" />
            <b-icon-question-circle
                class="help-icon"
                variant="kalkin-2"
                font-scale="0.9"
                @mouseover="showHelp = true"
                @mouseleave="showHelp = false"
            />
        </div>

        <div class="help" :class="{ 'show-help': showHelp }">
            <slot name="help" />
        </div>

        <div class="content" :class="{ 'hide-content': showHelp }">
            <slot name="content" />
        </div>
    </div>
</template>

<script>
export default {
    data: function() {
        return {
            showHelp: false,
        };
    },

    methods: {
        goStats() {
            this.$router.push({
                name: "Stats",
                params: { projectId: this.projectId },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.help-icon {
    cursor: help;
}

.k-card-4 {
    position: relative;

    & > .header {
        z-index: 30;
    }

    & > .help {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 100%;
        top: 35px;
        height: calc(100% - 35px);
        z-index: 10;
        opacity: 0;
        transition: opacity 0.5s;
    }
    & > .help.show-help {
        opacity: 1;
    }

    & > .content {
        display: flex;
        flex-direction: column;
        gap: 5px;
        flex: 1;

        width: 100%;
        height: 100%;
        z-index: 20;
        background: #fff;
        opacity: 1;
        transition: opacity 0.5s;
    }

    & > .content.hide-content {
        opacity: 0;
    }
}
</style>
