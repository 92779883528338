<template>
    <CardWithHelp>
        <template v-slot:header>
            <b-icon-bar-chart-line font-scale="1.2" />
            <div>Statistiques</div>
        </template>

        <template v-slot:help>
            <ul class="k-help-list">
                <li>Consulter les statistiques de mon projet</li>
                <li>Comparer les statistiques entre plusieurs périodes</li>
                <li>Exporter les statistiques</li>
            </ul>
        </template>

        <template v-slot:content>
            <div class="flex-grow-1" />

            <div>
                <div class="k-flex-row text-center">
                    <div>
                        Résumé du cumul des statistiques de
                        <span class="text-kalkin-2">
                            toutes les installations
                        </span>
                        pour les
                        <span class="text-kalkin-2">
                            {{ duration }} derniers jours </span
                        ><br />
                        <small>
                            ( {{ dateStart | dateFromISO(true) }} -
                            {{ dateEnd | dateFromISO(true) }} )
                        </small>
                    </div>
                </div>
                <PanelStatsSummary
                    :project-id="projectId"
                    :installations="installations"
                    :from="dateStart"
                    :to="dateEnd"
                    :duration="duration"
                />
            </div>

            <div class="flex-grow-1" />

            <div class="k-flex-center">
                <b-button size="sm" variant="kalkin-2" @click="goStats">
                    Voir les statistiques <b-icon-arrow-right-circle />
                </b-button>
            </div>
        </template>
    </CardWithHelp>
</template>

<script>
import CardWithHelp from "@/components/ui/item/CardWithHelp";
import PanelStatsSummary from "@/components/model/stats/PanelStatsSummary";
import { getDateFromToday } from "@/tools/dates";

const TIMESPAN = 7;
const TIMESPAN_START = 1;

export default {
    components: {
        CardWithHelp,
        PanelStatsSummary,
    },

    props: {
        installations: Array,
    },

    data: function() {
        return {
            projectId: this.$route.params.projectId,
            dateStart: getDateFromToday(TIMESPAN_START + TIMESPAN - 1),
            duration: TIMESPAN,
            dateEnd: getDateFromToday(TIMESPAN_START),

            help: false,
        };
    },

    methods: {
        goStats() {
            this.$router.push({
                name: "Stats",
                params: { projectId: this.projectId },
            });
        },
    },
};
</script>
