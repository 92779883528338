<template>
    <div class="k-card-1">
        <div class="k-flex-row">
            <small>
                Jours actifs sur la période
            </small>
            <span
                class="k-stats-total"
                :class="{ 'text-secondary': !activity }"
            >
                {{ activity }}
            </span>
        </div>
        <div class="k-flex-row">
            <small> Interactions avec l'écran</small>
            <span class="k-stats-total" :class="{ 'text-secondary': !touches }">
                {{ touches }}
            </span>
        </div>
        <div class="k-flex-row">
            <small>Fiches consultées</small>
            <span class="k-stats-total" :class="{ 'text-secondary': !fiches }">
                {{ fiches }}
            </span>
        </div>
        <div class="text-kalkin-2 text-center">
            <small>
                Dernières données reçues :
                <b v-if="lastContact">{{ lastContact | daysSinceISO }}</b>
                <b v-else>Jamais</b>
            </small>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        projectId: { type: [String, Number], required: true },
        installations: { type: Array, required: true },
        from: { type: String, required: true },
        to: { type: String, required: true },
        duration: { type: Number, required: true },
    },

    data: function() {
        return {
            activity: "?",
            touches: "?",
            fiches: "?",
            lastContact: null,
        };
    },

    watch: {
        $props: {
            async handler() {
                if (this.installations.length) {
                    await this.fetchData();
                }
            },
            deep: true,
            immediate: true,
        },
    },

    methods: {
        async fetchData() {
            this.activity = await this.$store.dispatch("stats/getActivity", {
                projectId: this.projectId,
                installations: this.installations.map(i => i.id),
                start: this.from,
                end: this.to,
            });

            this.touches = await this.$store.dispatch("stats/getTouchesTotal", {
                projectId: this.projectId,
                installations: this.installations.map(i => i.id),
                start: this.from,
                end: this.to,
            });
            const topFiches = await this.$store.dispatch(
                "stats/getTouchesOnFiches",
                {
                    projectId: this.projectId,
                    installations: this.installations.map(i => i.id),
                    start: this.from,
                    end: this.to,
                    limit: this.count,
                },
            );
            this.fiches = topFiches.reduce((a, c) => a + c.touch, 0);

            this.lastContact = await this.$store.dispatch(
                "stats/getLastContact",
                {
                    projectId: this.projectId,
                    installations: this.installations.map(i => i.id),
                },
            );
        },
    },
};
</script>
