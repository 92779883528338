<template>
    <b-overlay
        class="k-page-flex"
        variant="white"
        spinner-variant="kalkin-2"
        :show="isLoading"
        z-index="10000"
    >
        <div class="k-page-title">
            <h1>Tableau de bord</h1>
            <em class="text-danger">
                <b-icon-exclamation-triangle-fill />
                Page en cours de développement
                <b-icon-exclamation-triangle-fill />
            </em>
        </div>
        <div class="k-page-body k-flex-col k-gap-10">
            <div class="d-flex k-gap-10 flex-wrap">
                <PanelInstall
                    :installations="installations"
                    style="flex: 2; min-width: 250px"
                />
                <PanelStat
                    :installations="installations"
                    style="flex: 1; min-width: 250px"
                />
            </div>

            <h4 class="k-flex-row m-1">
                Gestion de projet
                <div class="k-flex-line" />
            </h4>

            <div class="d-flex k-gap-10 flex-wrap">
                <PanelEdit style="flex: 2; min-width: 250px" />
                <PanelContact style="flex: 1; min-width: 250px" />
            </div>
        </div>
    </b-overlay>
</template>

<script>
import PanelEdit from "@/components/misc/member-config/PanelEdit";
import PanelStat from "@/components/misc/member-config/PanelStat";
import PanelInstall from "@/components/misc/member-config/PanelInstall";
import PanelContact from "@/components/misc/member-config/PanelContact";
export default {
    components: {
        PanelEdit,
        PanelContact,
        PanelStat,
        PanelInstall,
    },

    data: function() {
        return {
            config: {},
            installations: [],
            isLoading: false,
        };
    },

    methods: {
        async fetchConfig() {
            this.isLoading = true;
            const projectId = this.$route.params.projectId;
            const configId = this.$route.params.configId;
            this.config = await this.$store.dispatch(
                "application/getApplicationConfig",
                { projectId, configId },
            );
            this.isLoading = false;
        },
        async fetchInstallations() {
            this.isLoading = true;
            const projectId = this.$route.params.projectId;
            const configId = this.$route.params.configId;
            this.installations = await this.$store.dispatch(
                "application/getInstallations",
                { projectId, configId, teamviewer: true },
            );
            this.isLoading = false;
        },
    },

    async beforeMount() {
        await this.fetchConfig();
        await this.fetchInstallations();
    },
};
</script>

<style lang="scss" scoped>
.grid {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    height: calc(100% - 25px);
}

.col1 {
    flex-basis: 0;
    flex-grow: 2;
    min-width: 60%;
}
.col2 {
    flex-basis: 0;
    flex-grow: 1;
}
@media only screen and (min-width: 768px) {
    .col2 {
        min-width: 300px;
    }
}
@media only screen and (max-width: 767px) {
    .col1,
    .col2 {
        min-width: 100%;
    }
}
.row1 {
    min-height: 60%;
    display: grid;
    position: relative;
}
.row2 {
    min-height: 40%;
    display: grid;
}
</style>
