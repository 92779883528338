var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CardWithHelp',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('b-icon-geo-alt',{attrs:{"font-scale":"1.2"}}),_c('div',[_vm._v("Mes installations")])]},proxy:true},{key:"help",fn:function(){return [_c('ul',{staticClass:"k-help-list"},[_c('li',[_vm._v("Consulter la liste de mes installations")]),_c('li',[_vm._v("Visualiser mes installations sur une carte")])])]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"panel-content"},[_c('div',{staticClass:"k-card-1"},[_c('h1',{staticClass:"k-flex-row"},[_vm._v(" Général "),_c('div',{staticClass:"k-flex-line"})]),_c('div',{staticClass:"k-flex-row"},[_c('small',[_vm._v("Nombre d'installations")]),_c('div',{staticClass:"count"},[_c('b',[_vm._v(_vm._s(_vm.installData.total))])])]),_c('div',[_c('small',[_vm._v("Type d'installations")])]),_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"k-flex-center k-gap-s"},[_c('b',{staticClass:"count"},[_vm._v(_vm._s(_vm.installData.table))]),_vm._v(" "+_vm._s(_vm._f("plural")("table",_vm.installData.table))+" ")]),_c('div',{staticClass:"k-flex-center k-gap-s"},[_c('b',{staticClass:"count"},[_vm._v(_vm._s(_vm.installData.borne))]),_vm._v(" "+_vm._s(_vm._f("plural")("borne",_vm.installData.borne))+" ")])])]),_c('div',{staticClass:"k-card-1"},[_c('h1',{staticClass:"k-flex-row"},[_vm._v(" Données application "),_c('div',{staticClass:"k-flex-line"})]),_c('div',{staticClass:"k-flex-row"},[_c('small',[_vm._v("Mises à jour aujourd'hui")]),_c('b',{staticClass:"count",class:{
                            'text-secondary': _vm.installData.sitToday == 0,
                        }},[_vm._v(" "+_vm._s(_vm.installData.sitToday)+" ")])]),_c('div',{staticClass:"k-flex-row"},[_c('small',[_vm._v("Mises à jour hier")]),_c('b',{staticClass:"count",class:{
                            'text-secondary': _vm.installData.sitYesterday == 0,
                            'text-warning': _vm.installData.sitYesterday > 0,
                        }},[_vm._v(" "+_vm._s(_vm.installData.sitYesterday)+" ")])]),_c('div',{staticClass:"k-flex-row"},[_c('small',[_vm._v("Sans mise à jour récente")]),_c('b',{staticClass:"count",class:{
                            'text-danger': _vm.installData.sitLonger > 0,
                        }},[_vm._v(" "+_vm._s(_vm.installData.sitLonger)+" ")])])]),_c('div',{staticClass:"k-card-1"},[_c('h1',{staticClass:"k-flex-row"},[_vm._v(" Connexion "),_c('div',{staticClass:"k-flex-line"})]),_c('div',{staticClass:"k-flex-row"},[_c('small',[_vm._v("Connectées aujourd'hui")]),_c('b',{staticClass:"count",class:{
                            'text-secondary':
                                _vm.installData.teamviewerToday == 0,
                        }},[_vm._v(" "+_vm._s(_vm.installData.teamviewerToday)+" ")])]),_c('div',{staticClass:"k-flex-row"},[_c('small',[_vm._v("Sans connexion récente")]),_c('b',{staticClass:"count",class:{
                            'text-warning':
                                _vm.installData.teamviewerLonger > 0,
                        }},[_vm._v(" "+_vm._s(_vm.installData.teamviewerLonger)+" ")])]),_c('div',{staticClass:"k-flex-row"},[_c('small',[_vm._v("Sans information")]),_c('b',{staticClass:"count",class:{
                            'text-warning':
                                _vm.installData.teamviewerNoData > 0,
                        }},[_vm._v(" "+_vm._s(_vm.installData.teamviewerNoData)+" ")])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}]},_vm._l((_vm.installations),function(install){return _c('div',{key:install.id,staticClass:"border"},[_c('div',[_vm._v(_vm._s(install.type))]),_c('div',[_vm._v(_vm._s(install.name))]),_c('div',[_vm._v(_vm._s(install.place || "Lieu non définie"))]),_c('div',[_vm._v(_vm._s(install.contactedAt || "Jamais"))])])}),0)]),_c('div',{staticClass:"k-flex-center"},[_c('b-button',{attrs:{"size":"sm","variant":"kalkin-2","to":{ name: 'ProjectInstallations' }}},[_vm._v(" Plus de détails "),_c('b-icon-arrow-right-circle')],1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }