<template>
    <div class="content">
        <CardWithHelp>
            <template v-slot:header>
                <b-icon-gear font-scale="1.2" />
                <div>Configuration</div>
            </template>

            <template v-slot:help>
                <ul class="k-help-list">
                    <li>Modifier la page d’accueil</li>
                    <li>Modifier les partenaires</li>
                    <li>Changer les couleurs principales</li>
                </ul>
            </template>

            <template v-slot:content>
                <div class="flex-grow-1" />
                <div class="text-center">
                    <em>En cours de développement</em>
                </div>
                <div class="flex-grow-1" />
                <div class="k-flex-center">
                    <b-button size="sm" variant="secondary" disabled>
                        Aller à la configuration <b-icon-arrow-right-circle />
                    </b-button>
                </div>
            </template>
        </CardWithHelp>

        <CardWithHelp>
            <template v-slot:header>
                <b-icon-layout-wtf font-scale="1.2" />
                <div>Modules</div>
            </template>

            <template v-slot:help>
                <ul class="k-help-list">
                    <li>Gérer mes modules</li>
                </ul>
            </template>

            <template v-slot:content>
                <div class="flex-grow-1" />
                <div class="text-center">
                    <em>Aperçu en cours de développement</em>
                </div>
                <div class="flex-grow-1" />
                <div class="k-flex-center">
                    <b-button size="sm" variant="kalkin-2" @click="goModules">
                        Voir les modules <b-icon-arrow-right-circle />
                    </b-button>
                </div>
            </template>
        </CardWithHelp>

        <CardWithHelp>
            <template v-slot:header>
                <b-icon-menu-button-wide font-scale="1.2" />
                <div>Menus</div>
            </template>

            <template v-slot:help>
                <ul class="k-help-list">
                    <li>Organiser mes menus</li>
                    <li>Changer les couleurs et les icônes</li>
                    <li>Associer les données</li>
                </ul>
            </template>

            <template v-slot:content>
                <div class="flex-grow-1" />
                <div class="text-center">
                    <em>En cours de développement</em>
                </div>
                <div class="flex-grow-1" />
                <div class="k-flex-center">
                    <b-button size="sm" variant="secondary" disabled>
                        Gérer mes menus <b-icon-arrow-right-circle />
                    </b-button>
                </div>
            </template>
        </CardWithHelp>

        <CardWithHelp>
            <template v-slot:header>
                <b-icon-file-earmark-text font-scale="1.2" />
                <div>Données</div>
            </template>

            <template v-slot:help>
                <ul class="k-help-list">
                    <li>Récupérer les données de mon SIT</li>
                    <li>Vérifier la remontée des informations</li>
                </ul>
            </template>

            <template v-slot:content>
                <div class="flex-grow-1" />
                <div class="text-center">
                    <em>En cours de développement</em>
                </div>
                <div class="flex-grow-1" />
                <div class="k-flex-center">
                    <b-button size="sm" variant="secondary" disabled>
                        Gérer mes données <b-icon-arrow-right-circle />
                    </b-button>
                </div>
            </template>
        </CardWithHelp>
    </div>
</template>

<script>
import CardWithHelp from "@/components/ui/item/CardWithHelp";
export default {
    components: {
        CardWithHelp,
    },

    methods: {
        goModules() {
            this.$router.push({
                name: "ProjectListModules",
                params: { projectId: this.$route.params.projectId },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.content {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    & > div {
        flex: 1;
        min-width: max(45%, 200px);
    }
}
</style>
